/**
 * Helper used to check the status of server-side jobs.
 *
 * NOTE: modified from an exported object (how it had always been) to a proper JS class
 * in order to allow the Poller to run multiple polls asynchronously
 *
 * Explanation:
 * Poller creates a setInterval that runs an ajax request against a URL every (x) seconds.
 * It checks the response's data.responseText.complete, which returns either true or false
 * depending on if the job was succesfully run or not. If true, the poller will stop itself,
 * then run the callback passed to it. If false, it will check again in (x) seconds.
 *
 * Usage:
 * const myJobPoller = new Poller();
 * myJobPoller.init({params}, callback);
 *
 * Common Example:
 * flushCachePoller.init({
 *   url: $foo.attr('data-poll')
 * }, function () {
 *   alert('Cache flushed successfully');
 * });
 */

import $ from 'jquery';

export default class Poller {


	constructor () {
		this.interval = 10000;
		// Setup variables which we'll use a functions later

		// The URL passed into poller from an outside js file
		this.params = null;

		// A function passed into poller that will run after poller is completed.
		this.callback = null;

		// This will get populated with the data from the request to send back to the callback function
		this.callbackData = {};

		// Used within jobCheck to alert other functions about the job status.
		this.status = false;
	}


	// Init sets the variables and calls Functions until the job is resolved
	init (params, callback) {
		this.params = params;
		this.callback = callback;
		this.start();
	}

	// Repeats a check against the submitted job until the job has finished.
	start () {
		this.status = false;

		// A looping function that checks a URL for a response every 2.5 seconds
		this.pollerId = setInterval(() => {
			// Stops the setInterval if the jobCheck is true (completed)
			if (this.jobCheck()) {
				this.stop();
			}
		}, this.interval);
	}

	// The ajax call that returns either True or False, depending if the job has finished or not.
	jobCheck () {
		// Merge defaults with options
		const options = $.extend({}, this.defaults, this.params);

		$.ajax({
			dataType: 'json',
			type: options.type,
			url: options.url,
			success: (data) => {
				if (typeof data === 'object') {
					const response = data;
					const status = response.complete;

					let refresh = false;
					let completeUrl = '';

					if (typeof response.refresh !== 'undefined') {
						refresh = response.refresh;

						this.resetInterval(response.interval);

						if (refresh === 'true' || refresh === true) {
							window.location.reload();
						}
					}

					if (status === true && typeof response.completeurl !== 'undefined') {
						completeUrl = response.completeurl;

						window.location.href = completeUrl;
					} else if (status === true) {
						this.status = status;
						this.callbackData = response;
						this.stop();
					}

					this.status = status;
					this.callbackData = response;
				}
			}
		});

		return self.status;
	}

	resetInterval (interval) {
		if (interval) {
			// Convert from seconds to milliseconds
			const parsedInterval = parseInt(interval, 10) * 1000;
			if (parsedInterval !== this.interval) {
				clearInterval(this.pollerId);
				this.interval = parsedInterval;
				this.start();
			}
		}
	}

	// Disables the setInterval after the jobCheck has returned True.
	stop () {
		clearInterval(this.pollerId);

		this.callback(this.callbackData);
	}
}
