var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<h4 class=\"js-searchable\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":39}}}) : helper)))
    + "</h4>\n				<p class=\"js-searchable\">Invitation pending</p>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<h4 class=\"js-searchable\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":30},"end":{"line":8,"column":38}}}) : helper)))
    + " ("
    + alias4(((helper = (helper = lookupProperty(helpers,"username") || (depth0 != null ? lookupProperty(depth0,"username") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":52}}}) : helper)))
    + ")</h4>\n				<p class=\"js-searchable\">Invitation pending</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"dropdown --right pull-right\" tabindex=\"0\" role=\"button\" aria-label=\"List options for this invitation\" title=\"Invitation options\" subtitle=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":16,"column":154},"end":{"line":16,"column":163}}}) : helper)))
    + "\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "			<div class=\"dropdown --right pull-right\" tabindex=\"0\" role=\"button\" aria-label=\"List options for this invitation\" title=\"Invitation options\" subtitle=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":18,"column":154},"end":{"line":18,"column":162}}}) : helper)))
    + "\">\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr data-username=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":19},"end":{"line":1,"column":25}}}) : helper)))
    + "\">\n	<td width=\"500\" class=\"display-flex --align-center\">\n		<div class=\"org-info\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmail") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":10,"column":10}}})) != null ? stack1 : "")
    + "		</div>\n	</td>\n\n	<td align=\"right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmail") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":15,"column":2},"end":{"line":19,"column":9}}})) != null ? stack1 : "")
    + "			<div class=\"dropdown__toggle\">\n				<span class=\"icon dropdown__ellipsis\">\n					<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 19.9727 4\" width=\"19.9727\" height=\"4\"><path d=\"M16.59.59a1.98 1.98 0 0 1 2.82 0 2.061 2.061 0 0 1 0 2.83A2 2 0 0 1 16 2a2.02 2.02 0 0 1 .15-.77 1.88 1.88 0 0 1 .44-.64zM3.66 3.11a2.323 2.323 0 0 0 .19-.34 1.8 1.8 0 0 0 .11-.38 2.054 2.054 0 0 0 0-.78 1.796 1.796 0 0 0-.11-.38 2.328 2.328 0 0 0-.19-.34 1.445 1.445 0 0 0-.25-.3 1.98 1.98 0 0 0-2.82 0A1.983 1.983 0 0 0 0 2a2.024 2.024 0 0 0 .59 1.42 2.004 2.004 0 0 0 2.82 0 1.832 1.832 0 0 0 .25-.31zm8 0a2.323 2.323 0 0 0 .19-.34 1.641 1.641 0 0 0 .11-.38 2.054 2.054 0 0 0 0-.78 1.796 1.796 0 0 0-.11-.38 2.328 2.328 0 0 0-.19-.34 1.445 1.445 0 0 0-.25-.3A1.966 1.966 0 0 0 10 0a2.028 2.028 0 0 0-.77.15 1.888 1.888 0 0 0-.64.44A1.966 1.966 0 0 0 8 2a2.001 2.001 0 0 0 3.41 1.42 1.832 1.832 0 0 0 .25-.31z\"/></svg>\n				</span>\n			</div>\n\n			<ul class=\"dropdown__list\">\n				<li class=\"dropdown__item\">\n					<a href=\"#\" class=\"js-resend-invite\" data-trigger-confirm>Resend invitation</a>\n				</li>\n				<li class=\"dropdown__item\">\n					<a href=\"#\" class=\"js-revoke-invite  --error\" data-trigger-confirm>Revoke invitation</a>\n				</li>\n			</ul>\n		</div>\n	</td>\n</tr>\n";
},"useData":true});