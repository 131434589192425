var Handlebars = require("../../../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "					Invitation\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":8,"column":5},"end":{"line":8,"column":13}}}) : helper)))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "				<p>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"email","hash":{},"data":data,"loc":{"start":{"line":14,"column":7},"end":{"line":14,"column":16}}}) : helper)))
    + "</p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "				<div class=\"label --green\">Org</div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<ul class=\"dropdown__list\">\n						<li class=\"dropdown__item\">\n							<a class=\"js-resend-invitation\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"resendUrl") || (depth0 != null ? lookupProperty(depth0,"resendUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"resendUrl","hash":{},"data":data,"loc":{"start":{"line":33,"column":45},"end":{"line":33,"column":58}}}) : helper)))
    + "\">Resend Invitation</a>\n						</li>\n						<li class=\"dropdown__item\">\n							<a class=\"js-revoke-invitation\" href=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"revokeUrl") || (depth0 != null ? lookupProperty(depth0,"revokeUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"revokeUrl","hash":{},"data":data,"loc":{"start":{"line":36,"column":45},"end":{"line":36,"column":58}}}) : helper)))
    + "\">Revoke Invitation</a>\n						</li>\n						<li class=\"dropdown__item dropdown__loader\">\n							<span>\n								<div class=\"dropdown__thumb\">\n									<div class=\"loading --block white\"></div>\n								</div>\n								Loading...\n							</span>\n						</li>\n					</ul>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<ul class=\"dropdown__list --single-error\">\n						<li class=\"dropdown__item\">\n							<a class=\"js-remove-collaborator --error\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"removeUrl") || (depth0 != null ? lookupProperty(depth0,"removeUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"removeUrl","hash":{},"data":data,"loc":{"start":{"line":50,"column":55},"end":{"line":50,"column":68}}}) : helper)))
    + "\">Remove</a>\n						</li>\n						<li class=\"dropdown__item dropdown__loader\">\n							<span>\n								<div class=\"dropdown__thumb\">\n									<div class=\"loading --block white\"></div>\n								</div>\n								Loading...\n							</span>\n						</li>\n					</ul>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"slat collaborator_user\" id=\"collaborator_user_"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":1,"column":58},"end":{"line":1,"column":64}}}) : helper)))
    + "\">\n	<div class=\"slat__inner\">\n		<div class=\"slat__content\">\n			<h4>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmail") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "			</h4>\n\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":3},"end":{"line":15,"column":10}}})) != null ? stack1 : "")
    + "		</div>\n\n		<div class=\"slat__right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"org") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":3},"end":{"line":21,"column":10}}})) != null ? stack1 : "")
    + "\n			<div class=\"dropdown --right\" tabindex=\"0\" role=\"button\" aria-label=\"List options for this collaborator\" title=\"Collaborator options\" subtitle=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":23,"column":147},"end":{"line":23,"column":155}}}) : helper)))
    + "\">\n				<div class=\"dropdown__toggle --icon\">\n					<span class=\"icon dropdown__ellipsis\">\n						<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 19.9727 4\" width=\"19.9727\" height=\"4\"><path d=\"M16.59.59a1.98 1.98 0 0 1 2.82 0 2.061 2.061 0 0 1 0 2.83A2 2 0 0 1 16 2a2.02 2.02 0 0 1 .15-.77 1.88 1.88 0 0 1 .44-.64zM3.66 3.11a2.323 2.323 0 0 0 .19-.34 1.8 1.8 0 0 0 .11-.38 2.054 2.054 0 0 0 0-.78 1.796 1.796 0 0 0-.11-.38 2.328 2.328 0 0 0-.19-.34 1.445 1.445 0 0 0-.25-.3 1.98 1.98 0 0 0-2.82 0A1.983 1.983 0 0 0 0 2a2.024 2.024 0 0 0 .59 1.42 2.004 2.004 0 0 0 2.82 0 1.832 1.832 0 0 0 .25-.31zm8 0a2.323 2.323 0 0 0 .19-.34 1.641 1.641 0 0 0 .11-.38 2.054 2.054 0 0 0 0-.78 1.796 1.796 0 0 0-.11-.38 2.328 2.328 0 0 0-.19-.34 1.445 1.445 0 0 0-.25-.3A1.966 1.966 0 0 0 10 0a2.028 2.028 0 0 0-.77.15 1.888 1.888 0 0 0-.64.44A1.966 1.966 0 0 0 8 2a2.001 2.001 0 0 0 3.41 1.42 1.832 1.832 0 0 0 .25-.31z\"/></svg>\n					</span>\n				</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isEmail") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":30,"column":4},"end":{"line":61,"column":11}}})) != null ? stack1 : "")
    + "			</div>\n		</div>\n	</div>\n</div>\n";
},"useData":true});